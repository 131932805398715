import React from 'react'
import { Router } from '@reach/router'
import Main from '../../layouts/Main'
import { Transition } from '../../components/'
import EstimateDetail from '../../views/EstimateDetail'
import EstimateList from '../../views/EstimateList'
import { Private } from '../../utils/authentication'

export default () => {
  return (
    <Private>
      <Main>
        <Transition>
          <Router>
            <EstimateList path='/estimates' />
            <EstimateDetail path='/estimates/:id' />
          </Router>
        </Transition>
      </Main>
    </Private>
  )
}
