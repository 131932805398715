import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { format } from 'date-fns'
import { is } from 'date-fns/locale'
import { useApi } from '../../utils/api'
import styles from './EstimateDetail.module.scss'

export default ({ id }) => {
  const {
    loading,
    data
  } = useApi(`/estimates/${id}`)

  return (
    <section>
      <div className='container pt-5'>
        {(!loading && data) ? <Data data={data} /> : <Skel />}
      </div>
    </section>
  )
}

const calculateTotalFromLineItems = lineItems => {
  return lineItems
    .map(({ quantity }) => quantity)
    .reduce((acc, q) => acc + q, 0)
}

const PricingTable = ({ lineItems }) => {
  const total = calculateTotalFromLineItems(lineItems)

  return (
    <table className={`table ${styles.table}`}>
      <tbody>
        {lineItems.map(item => (
          <tr key={item.id}>
            <td>
              <h5>{item.description}</h5>
              <p>{item.kind}</p>
            </td>
            <td>{item.quantity} tímar</td>
          </tr>
        ))}
      </tbody>

      <tfoot>
        <tr>
          <td>
            <h4>Samtals</h4>
            <small>
              Ath. Verkefnastjórnun, efnisvinna og annað er
              ekki innifalið í þessari áætlun.
            </small>
          </td>
          <td>
            <span className={styles.total}>{total} tímar</span>
          </td>
        </tr>
      </tfoot>
    </table>
  )
}

const Skel = () => (
  <div className={styles.container}>
    <section className={styles.firstPage}>
      <h2><Skeleton width='50%' /></h2>
      <h4><Skeleton width='90%' /></h4>

      <h6><Skeleton /></h6>
    </section>
  </div>

)

const Data = ({ data }) => (
  <div className={styles.container}>
    <section className={styles.firstPage}>
      <h2>{data.client.name}</h2>
      <h4>{data.purchase_order}</h4>

      <h6>{format(new Date(), 'd. MMMM, yyyy', { locale: is })}</h6>
    </section>

    <section>
      <p>{data.subject}</p>
    </section>

    <section>
      <h3>Áætlaðir tímar</h3>

      <p>
        <small>
          Ath. eingöngu er um áætlun að ræða og öll vinna eða kostnaðarliðir
          sem ekki eru tilgreindir hér munu hafa áhrif á endanlegan tímafjölda.
        </small>
      </p>

      <PricingTable lineItems={data.line_items} />

      <Extras />
    </section>
  </div>
)

const Extras = () => (
  <>
    <h3>Annað</h3>

    <h4>“Meta tög” fyrir samfélagsmiðla</h4>
    <p>
      Við göngum úr skugga um að þegar vef eða síðu er deilt á samfélagsmiðlum
      eins og Facebook, að skilgreiningar séu til staðar svo deilingin komi vel út
      á öllum síðum.
    </p>

    <h4>Góð uppsetning fyrir leitarvélar og “botta”</h4>
    <p>
      Vefurinn verður forritaður og útfærður þannig að hann verður auðskannanlegur
      af leitarvélum og öðrum “bottom”.  Við skilgreinum SEO section í Prismic til
      að yfirskrifa default hegðun sniðmáta (td. Að titill síðu sé notaður í
      titil-tag vefs, ef þess þarf)
    </p>

    <h4>Mælingar - analytics</h4>
    <p>
      Við setjum upp Google Analytics, Siteimprove eða önnur mælingatæki á vefnum,
      eftir þörfum hvers og eins. Þetta eru algeng verkfæri og gefa manni mikla og
      góða innsýn í hvernig vefirnir eru notaðir
    </p>

    <h4>Aðgengisprófanir</h4>
    <p>
      Aðgengismál eru alltaf að verða mikilvægari partur af gerð vefja. Við sjáum
      ekki um að sérstaklega aðgengisprófa vefi, en getum séð til þess að vefur
      uppfylli aðgengisprófanir fyrir aðgengisstaðla.
    </p>

    <p>
      Ef vilji er fyrir því að láta vef raunverulega vera aðgengilegann sjóndöprum
      og annara sem á þurfa, þá er þetta ekki nóg, og best er að fá reyndan
      aðgengissérfræðing til að aðstoða með það. Og ekkert er betra en raunveruleg
      aðgengisprófun af raunverulegum notendum.
    </p>
  </>
)
