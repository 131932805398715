import React from 'react'
import { navigate } from 'gatsby'
import Skeleton from 'react-loading-skeleton'
import { FadeBottom, Card } from '../../components/'
import { useApi } from '../../utils/api'

const Skel = () => (
  [0, 0, 0].map((x, i) => (
    <Card withPadding key={i}>
      <h6><Skeleton width='45%' /></h6>
      <h4><Skeleton width='60%' /></h4>

      <p><Skeleton /></p>
      <p><Skeleton width={100} /></p>
    </Card>
  ))
)

export default () => {
  const {
    loading,
    data,
    error
  } = useApi('/estimates')

  return (
    <section>
      <div className='container'>
        <div className='row'>
          <div className='col-6'>
            <h3>Áætlanir</h3>
            { loading && (
              <Skel />
            )}

            {error && (
              <h1 style={{ color: 'red' }}>Error! {error.toString()}</h1>
            )}

            {data && (
              <List estimates={data.estimates} />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

const calculateTotalFromLineItems = lineItems => {
  return lineItems
    .map(({ quantity }) => quantity)
    .reduce((acc, q) => acc + q, 0)
}

const List = ({ estimates }) => {
  return estimates.map(est => {
    const total = calculateTotalFromLineItems(est.line_items)

    const open = () => navigate(`/estimates/${est.id}`)

    return (
      <FadeBottom key={est.id}>
        <Card
          tabIndex={0}
          role='link'
          onKeyPress={open}
          onClick={open}
          withPadding
        >
          <h6>{est.client.name}</h6>
          <h4>{est.purchase_order}</h4>

          <p>{est.subject}</p>
          <p>Samtals tímar: {total}</p>
        </Card>
      </FadeBottom>
    )
  })
}
